<template>
  <footer :id="!id ? 'footer' : id" :class="footerClass">
    <div v-if="footerType == 'simple'" class="simple-content bg-neutral-gray-50">
      <div class="r:max-w-[672px] mx-auto px-4 pt-14 pb-8">
        <p class="r:text-[12px] leading-[1.4] text-tw-black-450 font-notosans mb-8" v-html="viewsJa.common.footer.common_info"></p>
        <p class="r:text-[12px] leading-[1.5] r:tracking-[1.4px] font-notosans">&copy;Money Forward, Inc.</p>
      </div>
    </div>
    <div v-else class="top-content">
      <div class="top">
        <div class="r:max-w-[672px] mx-auto px-4">
          <div class="pt-6 pb-10">
            <div class="flex items-center justify-center mb-6 test-social">
              <social-sharing name="facebook" :shareUrl="shareUrl" class="px-2" />
              <social-sharing name="twitter" :shareUrl="shareUrl" twitterTitle="" twitterHastag="" class="px-2" />
              <social-sharing name="line" :shareUrl="shareUrl" class="px-2" />
            </div>
            <template v-if="hasFormNumber">
              <p class="r:text-[12px] leading-[1.4] text-tw-black-450 font-notosans r:mb-[38px]" v-html="viewsJa.common.footer.common_info"></p>
            </template>
            <images name="logo" />
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="r:max-w-[672px] mx-auto px-4 bg-neutral-gray-700">
          <div class="py-6 xs:py-8">
            <div class="flex mb-8 -mx-1">
              <a href="https://fixedcost.moneyforward.com/" target="_blank" rel="noopener" class="mx-1 px-2 py-4 xs:px-4 rounded-lg bg-white w-1/3 r:min-h-[105px] flex flex-col items-center hover:opacity-70 transition" id="external-service-fixedcost" data-testid="external-service-fixedcost">
                <images id="external-service-fixedcost-img" name="logoFixedCost" class="mb-4" />
                <span id="external-service-fixedcost-span" class="r:text-[12px] leading-[1.33] r:tracking-[0.25px] text-neutral-gray-600">{{ viewsJa.common.footer.link_to_services.fixed_cost }}</span>
              </a>
              <a href="https://fp.moneyforward.com/" target="_blank" rel="noopener" class="mx-1 px-2 py-4 xs:px-4 rounded-lg bg-white w-1/3 r:min-h-[105px] flex flex-col items-center hover:opacity-70 transition" id="external-service-fp" data-testid="external-service-fp">
                <images id="external-service-fp-img" name="logoFPMatching" class="mb-4" />
                <span id="external-service-fp-span" class="r:text-[12px] leading-[1.33] r:tracking-[0.25px] text-neutral-gray-600">{{ viewsJa.common.footer.link_to_services.fp_matching }}</span>
              </a>
              <a href="https://relay.moneyforward.com/" target="_blank" rel="noopener" class="mx-1 px-2 py-4 xs:px-4 rounded-lg bg-white w-1/3 r:min-h-[105px] flex flex-col items-center hover:opacity-70 transition" id="external-service-guardian" data-testid="external-service-guardian">
                <images id="external-service-guardian-img" name="logoGuardian" class="mb-4" />
                <span id="external-service-guardian-span" class="r:text-[12px] leading-[1.33] r:tracking-[0.25px] text-neutral-gray-600">{{ viewsJa.common.footer.link_to_services.guardian }}</span>
              </a>
            </div>
            <ul class="flex flex-wrap mb-4 -mx-2 r:max-w-[340px]">
              <li class="px-2 pb-4 leading-[1.2]">
                <a :href="$routes.policy_comparison()" class="text-white r:text-[14px] leading-[1.2] underline hover:opacity-70 transition" target="_blank" rel="noopener" id="footer-link-comparison" data-testid="footer-link-comparison">{{ viewsJa.common.footer.agency_policy_link }}</a>
              </li>
              <li class="px-2 pb-4 leading-[1.2]">
                <a :href="$routes.policy_inducement()" class="text-white r:text-[14px] leading-[1.2] underline hover:opacity-70 transition" target="_blank" rel="noopener" id="footer-link-inducement" data-testid="footer-link-inducement">{{ viewsJa.common.footer.solicitation_policy_link }}</a>
              </li>
              <li class="px-2 pb-4 leading-[1.2]">
                <a :href="$routes.faqs()" class="text-white r:text-[14px] leading-[1.2] underline hover:opacity-70 transition" target="_blank" rel="noopener" id="footer-link-faqs" data-testid="footer-link-faqs">{{ viewsJa.common.footer.faq_link }}</a>
              </li>
              <li class="px-2 pb-4 leading-[1.2]">
                <a :href="$routes.contact()" class="text-white r:text-[14px] leading-[1.2] underline hover:opacity-70 transition" target="_blank" rel="noopener" id="footer-link-contact" data-testid="footer-link-contact">{{ viewsJa.common.footer.contact_link }}</a>
              </li>
              <li class="px-2 pb-4 leading-[1.2]">
                <a href="https://corp.moneyforward.com/privacy/" class="text-white r:text-[14px] leading-[1.2] underline hover:opacity-70 transition" target="_blank" rel="noopener" id="footer-link-corp-privacy" data-testid="footer-link-corp-privacy">{{ viewsJa.common.footer.privacy_link }}</a>
              </li>
              <li class="px-2 pb-4 leading-[1.2]">
                <a href="https://corp.moneyforward.com/" class="text-white r:text-[14px] leading-[1.2] underline hover:opacity-70 transition" target="_blank" rel="noopener" id="footer-link-corp" data-testid="footer-link-corp">{{ viewsJa.common.footer.operating_company_link }}</a>
              </li>
              <li class="px-2 pb-4 leading-[1.2]">
                <a href="https://corp.moneyforward.com/privacy/external-tools/#home" class="text-white r:text-[14px] leading-[1.2] underline hover:opacity-70 transition" target="_blank" rel="noopener" id="footer-link-external-tools" data-testid="footer-link-external-tools">{{ viewsJa.common.footer.external_tools_link }}</a>
              </li>
            </ul>
            <p class="text-white leading-[1.1] r:text-[14px] font-notosans">&copy;Money Forward, Inc.</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import SocialSharing from '@/Shared/User/SocialSharing.vue';
import Images from '@/Shared/Images.vue';
export default {
  components: {
    Images,
    SocialSharing
  },
  props: {
    id: {
      type: String,
      required: false
    },
    footerType: {
      type: String,
      default: 'top'
    },
    footerClass: {
      type: String,
      default: ''
    },
    hasFormNumber: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      viewsJa: this.jaConfig.viewsJa,
      shareUrl: window.location.origin
    }
  },
}
</script>
